<template>
  <v-radio-group v-model="selectedLocationId" dense hide-details :rules="rules">
    <v-radio
      v-for="itemLocation in form.service.locations"
      :key="itemLocation.id"
      :value="itemLocation.id"
      :disabled="disabled"
    >
      <template #label>
        <div class="d-flex flex-wrap align-center">
          <v-icon
            v-if="getIconForType(itemLocation.type)"
            class="mr-2"
            color="primary"
          >
            {{ getIconForType(itemLocation.type) }}
          </v-icon>
          <img
            v-else
            height="15"
            class="mr-2 ml-1"
            :src="getImageForType(itemLocation.type)"
            :alt="$trans('service_location_' + itemLocation.type)"
          />
          {{ $trans("service_location_" + itemLocation.type) }}
          <span v-if="itemLocation.data" class="ml-2"
            >({{ itemLocation.data }})</span
          >
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import serviceLocationActions from "@/calendesk/mixins/serviceLocationActions";

export default {
  mixins: [serviceLocationActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    form: {
      type: Object,
      default: null,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocationId: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedLocationId = val;
    },
    selectedLocationId(locationId) {
      this.$emit("change", locationId);
      this.$emit("input", locationId);
    },
  },
};
</script>
