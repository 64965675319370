<template>
  <v-select
    v-model="selectedStatus"
    outlined
    hide-details="auto"
    :items="bookingStatuses.inputValues()"
    :label="$trans('status')"
    :disabled="disabled"
    :rules="rules"
    class="cd-fake-outer"
    :attach="'#' + uniqueId"
    :menu-props="{ offsetY: true }"
    small-chips
    deletable-chips
    clearable
    @change="onChange"
  >
    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-select>
</template>
<script>
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";

export default {
  mixins: [sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStatus: this.value,
      bookingStatuses,
    };
  },
  watch: {
    value(val) {
      this.selectedStatus = val;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
